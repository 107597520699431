<template>
	<div>
		<div class="column is-full">
			<div class="card events-card is-size-6">
				<header class="card-header">
					<p class="card-header-title">
					Pagos
					</p>
					<a href="#" class="card-header-icon" aria-label="more options">
						<span class="icon">
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</span>
					</a>
				</header>
				<div class="card-table">
					<div class="content">
						<table class="table is-fullwidth is-striped is-size-7">
							<thead v-if="payments.length > 0">
								<th></th>
								<th>Descripcion</th>
                <th>Valor</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-if="payments.length === 0">
									<td width="5%"><i class="fa fa-file"></i></td>
									<td>Sin detalle</td>
								</tr>
								<tr v-for="payment in payments" :key="payment.id">
									<td width="5%"><i class="fa fa-file"></i></td>
									<td>{{ payment.description }}</td>
                  <td>${{ formatPrice(payment.value) }}</td>
									<td class="level-right">
										<div class="buttons">
											<a v-if="hasWritePermission" class="button is-small is-primary is-light is-danger has-text-left" @click.prevent="deletePayment(payment)">
												<span class="icon">
													<i class="fa fa-trash"></i>
												</span>
											</a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<footer class="card-footer">
				</footer>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data: () => ({
		invoice: { id: 0 },
		payments: []
  }),
  props: {
		invoiceCompaniesProp: Object,
		hasWritePermission: Boolean
  },
	watch: {
		invoiceCompaniesProp: function(newVal) {
			this.invoice = newVal
			this.payments = newVal.payments
		}
	},
	methods: {
		formatPrice(value) {
      let val = (value/1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
		async deletePayment(payment) {
			console.log('1 deletePayment', payment)
			this.$emit('delete-payment', payment)
		}
	}
}
</script>