<template>
	<div>
		<div class="column is-full">
			<div class="card events-card is-size-6">
				<header class="card-header">
					<p class="card-header-title">
					Documentos
					</p>
					<a href="#" class="card-header-icon" aria-label="more options">
						<span class="icon">
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</span>
					</a>
				</header>
				<div class="card-table">
					<div class="content">
						<table class="table is-fullwidth is-striped is-size-7">
							<thead v-if="InvoiceCompanyFiles.length > 0">
								<th></th>
								<th>Nombre</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-if="InvoiceCompanyFiles.length === 0">
									<td width="5%"><i class="fa fa-file"></i></td>
									<td>Sin documentos adjuntos</td>
								</tr>
								<tr v-for="file in InvoiceCompanyFiles" :key="file.id">
									<td width="5%"><i class="fa fa-file"></i></td>
									<td>{{ file.name }}</td>
									<td class="level-right">
										<div class="buttons">
											<a v-if="hasWritePermission" class="button is-small is-primary is-light is-danger has-text-left" @click.prevent="deleteFile(file.id)">
												<span class="icon">
													<i class="fa fa-trash"></i>
												</span>
											</a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<footer class="card-footer">
					<a v-if="hasWritePermission" @click.prevent="eventModalFiles()" class="card-footer-item button is-primary is-light">
						<span class="icon is-small">
							<i class="fa fa-plus-square"></i>
						</span>
						<span>Nuevo</span>
					</a>
				</footer>
			</div>
		</div>
		<!-- MODAL -->
		<div class="modal" id="modalFiles">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">Agregar Documento</p>
					<button class="delete"  @click.prevent="eventModalFiles()" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
					
					<input
						class="input"
						type="text"
						id="secretCompany"
						placeholder="Nombre"
					>
				</section>
				<footer class="modal-card-foot">
					<button class="button is-success" @click.prevent="addSecret()">Agregar</button>
					<button class="button" @click.prevent="cancelModal()">Cancel</button>
				</footer>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data: () => ({
    InvoiceCompanyFiles: []
  }),
  props: {
		InvoiceCompanyFilesProp: Array,
		hasWritePermission: Boolean
  },
	watch: {
		InvoiceCompanyFilesProp: function(newVal) {
			this.InvoiceCompanyFiles = newVal
		}
	}
}
</script>