<template>
  <div class="container">  
    <p class="is-size-2">{{ getMonthName(invoiceCompany.period.month, invoiceCompany.period.year) }} / {{ invoiceCompany.period.year }} </p>
    <p class="is-size-6 has-text-weight-semibold">{{ invoiceCompany.company_name }}</p>
    <a v-if="!invoiceCompany.paid" class="button is-rounded is-primary is-large has-text-weight-medium is-family-monospace" @click.prevent="confirmModal()">
      
      <span class="icon">
        <i class="fa-solid fa-hand-holding-dollar"></i>
      </span> 
      <div>${{ formatPrice(invoiceCompany.total - invoiceCompany.payment) }}</div>
    </a>
    <span v-else>
      <i class="fa-solid fa-check"></i>
    </span>    
    
    <invoiceDetailCompany :invoiceCompaniesProp="invoiceCompany" @update-invoices="updateInvoice()" />
    <invoicePaymentsVue :invoiceCompaniesProp="invoiceCompany" @delete-payment="deletePayment" />
    <invoiceCompanyFiles />
    <div id="confirm-pay-modal" class="modal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <div class="modal-card-body">
          <div class="box">
            <p>Confirmar pago?</p>
            <!-- Your content -->
          </div>
        </div>
        <footer class="modal-card-foot">
          <button class="button is-success" @click.prevent="toPay()">Sí</button>
          <button class="button" @click.prevent="confirmModal()">Cancelar</button>
        </footer>
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>
    <div class="modal" id="modalConfirmDelete">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
        <p class="modal-card-title">Acción Destructiva</p>
        <button class="delete"  @click.prevent="eventModalDelete()" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          Confirmar ELIMINAR abono
        </section>
        <footer class="modal-card-foot has-text-centered inline-block">
          <button class="button is-danger" @click.prevent="deletePaymentConfirm()">Eliminar</button>
          <button class="button" @click.prevent="eventModalDelete()">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import invoiceCompanyFiles from '@/components/billing/invoiceCompanyFiles.vue'
import invoiceDetailCompany from '@/components/billing/invoiceDetailCompany.vue'
import invoicePaymentsVue from '@/components/billing/invoicePayments.vue'

export default {
  name: 'InvoiceCompanyView',
  components: {
    invoiceCompanyFiles,
    invoiceDetailCompany,
    invoicePaymentsVue
  },
  data: () => ({
    invoiceCompany: { paid: false, details: [], period: { month: '', year: '' } }
  }),
  methods: {
    async toPay() {
      const invoiceId = this.$route.params.id
      // console.log(invoiceId)
      this.$axios
      .post('billings/toPay', { invoiceCompany: { id: invoiceId} } )
      .then(response => {
        this.confirmModal()
        if(response.data.result === 'OK')
          // this.invoiceCompany.paid = true
          this.getInvoiceCompany()
        // this.$router.push({ name: "BillingView", params: { id: this.invoiceCompany.billing_id }})
      })
    },
    confirmModal() {
      const Modal = document.querySelector(`#confirm-pay-modal`)
      Modal.classList.toggle('is-active')
    },
    async getInvoiceCompany() {
      const invoiceId = this.$route.params.id
      this.invoiceCompany.id = invoiceId
      await this.$axios
      .get('billings/invoiceGet', { params: { invoiceCompanyId: this.invoiceCompany.id } })
      .then(response => {
        this.invoiceCompany = response.data.InvoiceCompany
      })
      .catch(error => console.log(error))
    },
    getMonthName (month, year) {
      const newDate = new Date(year, month - 1, 1)
      const monthName = newDate.toLocaleString('es-cl', { month: 'long' })
      return monthName.charAt(0).toUpperCase() + monthName.slice(1)
    },
    formatPrice(value) {
      let val = (value/1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }, 
    async updateInvoice() {
      this.getInvoiceCompany()
    },
    async deletePayment(payment) {

      console.log('2 deletePayment', payment)
      this.tmpPayment = payment
      this.eventModalDelete()

    },
    eventModalDelete () {
      const Modal = document.querySelector(`#modalConfirmDelete`)
      Modal.classList.toggle('is-active')
    },
    async deletePaymentConfirm() {
      await this.$axios
			.post('payment/delete', { payment: this.tmpPayment })
			.then(response => {
				const result = response.data.result
				if (result === 'OK') {
          this.updateInvoice()
					this.eventModalDelete()
				} else {
					alert('Ha ocurrido un error')
				}
			})
			.catch(error => console.error(error))
    }
  },
  mounted() {
    this.getInvoiceCompany()
  }
}
</script>

<style scoped>
	.label {
		position: absolute;
		top: -5px;
		right: -2px;
		height: 20px;
		width: 20px;
		background-color: red;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		border: 1px solid white;
	}
</style>
