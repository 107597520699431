<template>
	<div>
		<div class="column is-full">
			<div class="card events-card is-size-6">
				<header class="card-header">
					<p class="card-header-title">
					Detalle Facturación
					</p>
					<a href="#" class="card-header-icon" aria-label="more options">
						<span class="icon">
							<i class="fa fa-angle-down" aria-hidden="true"></i>
						</span>
					</a>
				</header>
				<div class="card-table">
					<div class="content">
						<table class="table is-fullwidth is-striped is-size-7">
							<thead v-if="details.length > 0">
								<th></th>
								<th>Descripcion</th>
                <th>Valor</th>
								<th></th>
							</thead>
							<tbody>
								<tr v-if="details.length === 0">
									<td width="5%"><i class="fa fa-file"></i></td>
									<td>Sin detalle</td>
								</tr>
								<tr v-for="detail in details" :key="detail.id">
									<td width="5%"><i class="fa fa-file"></i></td>
									<td>{{ detail.description }}</td>
                  <td>${{ formatPrice(detail.total) }}</td>
									<td class="level-right">
										<div class="buttons">
											<a v-if="!invoice.paid && hasWritePermission" class="button is-small is-primary is-light is-danger has-text-left" @click.prevent="deleteDetail(detail.id)">
												<span class="icon">
													<i class="fa fa-trash"></i>
												</span>
											</a>
											<a v-else class="button is-small is-primary is-light is-danger has-text-left" disabled>
												<span class="icon">
													<i class="fa fa-trash"></i>
												</span>
											</a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<footer class="card-footer">
					<a v-if="!invoice.paid && hasWritePermission" @click.prevent="eventModalInvoiceDetailForm()" class="card-footer-item button is-primary is-light">
						<span class="icon is-small">
							<i class="fa fa-plus-square"></i>
						</span>
						<span>Nuevo</span>
					</a>
				</footer>
			</div>
		</div>
		<!-- MODAL -->
		<div class="modal" id="modalInvoiceDetailForm">
			<div class="modal-background"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">Agregar</p>
					<button class="delete"  @click.prevent="eventModalInvoiceDetailForm()" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
					
					<input
						v-model="newDetail.description"
						class="input"
						type="text"
						id="description"
						placeholder="Descripcion"
					>
					<input v-model="newDetail.total" class="input" type="number" min="1" step="any" placeholder="Valor" />
				</section>
				<footer class="modal-card-foot">
					<button class="button is-success" @click.prevent="createDetail()">Agregar</button>
					<button class="button" @click.prevent="eventModalInvoiceDetailForm()">Cancel</button>
				</footer>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data: () => ({
		invoice: { id: 0 },
		details: [],
		newDetail: { description: '', total: 1000 }
  }),
  props: {
		invoiceCompaniesProp: Object,
		hasWritePermission: Boolean
  },
	watch: {
		invoiceCompaniesProp: function(newVal) {
			this.invoice = newVal
			this.details = newVal.details
		}
	},
	methods: {
		formatPrice(value) {
      let val = (value/1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
		eventModalInvoiceDetailForm () {
			const Modal = document.querySelector(`#modalInvoiceDetailForm`)
      Modal.classList.toggle('is-active')
		},
		async createDetail(){
			this.newDetail.invoice_company_id = this.invoice.id
			await this.$axios
			.post('billing/createDetail', { detail: this.newDetail })
			.then(response => {
				const result = response.data.result
				if(result === 'OK') {
					this.newDetail.description = ''
					this.newDetail.total = 1000
					this.updateInvoice()
					this.eventModalInvoiceDetailForm()
				}
				else {
					alert('Ocurrión un error')
				}
				
				
			})
			.catch(error => console.log(error))
		},
		async deleteDetail(idDetail){
			await this.$axios
			.post('billing/deleteDetail', { detail: { id: idDetail} })
			.then(response => {
				const result = response.data.result
				if(result === 'OK') {
					this.updateInvoice()
				}
				else {
					alert('Ocurrión un error')
				}
				
				
			})
			.catch(error => console.log(error))
		},
		updateInvoice() {
			this.$emit('update-invoices')
		}
	}
}
</script>